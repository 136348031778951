// Neutral colors
$white: #ffffff;
$black: #000000;


// RGB
$red: #D32542;
$green: #0AB21B;


// Grey
$brand-grey: #454952;


// Brand Primary
$brand-primary: #eab1cf;


// Brand Secondary
$brand-secondary: $white;


// Text colors
$color-text-neutral: $white;
$color-text-brand: #7e5168;
$color-text-brand-light: #d776a8;


// Components
$header-gradient-start: #eab1cf;
$header-gradient-end: #e97ca3;

$card-rose-dark: #d59bb9;
$card-rose-light: #efafd0;

$card-shadow: #76223d;

$services-gradient-start: #94deba;
$services-gradient-end: #4ad2ca;

// Banners

// Customs
