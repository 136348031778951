.o-main-banner {
  position: relative;
  z-index: 2;
  margin-top: -40px;

  &__img {
    display: block;
    width: 100%;
    border-top-left-radius: 50% 3%;
    border-top-right-radius: 50% 3%;
  }

  &__info {
    margin-top: -30px;

    @include laptop {
      margin-top: -60px;
    }

    @include desktop {
      margin-top: -120px;
    }
  }
}
