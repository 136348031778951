.o-swiper {
  position: relative;

  .swiper-slide {
    height: auto;
  }

  .swiper-container {
    position: initial;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: $white;

    &:after {
     font-size: 30px;
    }
  }
}
