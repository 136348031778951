.o-gallery {
  position: relative;
  z-index: 3;

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }

  &__item {
    width: 100%;

   @include mobilePlus {
     //width: 50%;
   }

    &:nth-child(1) {
      display: flex;
      align-items: flex-end;

      @include tablet {
        width: 40%;
      }
    }

    &:nth-child(2) {
      @include tablet {
        width: 60%;
      }
    }

    &:nth-child(3) {
      @include tablet {
        width: 70%;
      }
    }

    &:nth-child(4) {
      @include tablet {
        width: 30%;
      }
    }

    &__inner {
      height: 100%;
      padding: 10px;

      @include tablet {
        height: auto;
        padding: 10px;
      }
    }

    a,
    img {
      display: block;
      width: 100%;
    }


    a {
      position: relative;
      overflow: hidden;

      &,
      &:before {
        border-radius: $general-border-radius;
      }

      &:before {
        content: '+';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 44px;
        background-color: rgba($brand-primary, .6);
        opacity: 0;
        transform: scale(.95);
        @include transition(opacity .3s ease, transform .3s ease);
      }

      &:hover:before {
        opacity: 1;
        transform: scale(1);
      }
    }

    img {}
  }

  .btn {
    background: #59d4c7;
    color: $white;
    padding: 10px 20px;
    border-radius: 20px;
    display: inline-block;
    width: auto;
    &:before {
      display: none;
    }
  }
}
