.o-nav {
  &__list {
    display: flex;
    align-items: center;
  }

  &__item {}

  &__link {
    position: relative;
    display: block;
    padding: 20px;
    font-weight: $font-weight-semiBold;

    @include desktopDown {
      font-size: 16px;
    }

    @include laptop {
      padding: 20px 30px;
    }

    @include desktop {
      padding: 20px 50px;
    }

    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 10px;
      left: 0;
      width: 30%;
      height: 2px;
      margin: auto;
      background-color: $white;
      transform: translateY(10px);
      opacity: 0;
      @include transition(transform .3s ease, opacity .3s ease, width .3s ease);
    }

    &:hover:before {
      width: 60%;
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.o-header .o-nav {
  @include laptopDown {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    z-index: 6;
    padding: 50px 0 200px;
    transform: translateY(-30px);
    background: linear-gradient(180deg,
            rgba($header-gradient-start, 1) 0%,
            rgba($header-gradient-end , 1) 100%);
    @include transition(opacity .3s ease, transform .3s ease);

    &:before {
      content: '';
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      height: 250px;
      background: linear-gradient(180deg,
              rgba($header-gradient-end, 1) 0%,
              rgba($header-gradient-end , 0) 100%);
      z-index: -1;
    }

    .o-nav__list,
    .o-nav__item,
    .o-nav__link {
      width: 100%;
    }

    .o-nav__list {
      flex-direction: column;
    }

    .o-nav__item {}

    .o-nav__link {
      text-align: center;
    }
  }

  &.active {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }
}

.o-footer .o-nav {
  margin-top: 60px;

  @include tablet {
    margin-top: 0;
    margin-right: -20px;
  }

  @include desktop {
    margin-right: -50px;
  }

  &__list {
    justify-content: center;
    flex-wrap: wrap;

    @include mobilePlusDown {
      flex-direction: column;
    }
  }

  &__link {
    @include tabletDown {
      padding: 10px 20px;
    }
  }
}
