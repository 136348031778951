// Hamburger menu
.c-hamburger {
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 11;
  padding: 20px 5px 20px 20px;
  overflow: hidden;

  @include customQuery('min-width: 400px') {
    //top: 18px;
  }

  @include mobilePlus {
    top: 30px;
  }

  @include laptop {
    display: none;
  }

  &__wrapper {
    width: 30px;
    height: 24px;
    position: relative;
    margin: auto;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 50%;
      background: $white;
      opacity: 1;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
    }

    &.active span {
      background: $white;
      height: 3px;
    }

    span:nth-child(even) {
      left: 50%;
      border-radius: 0 9px 9px 0;
    }

    span:nth-child(odd) {
      left:0;
      border-radius: 9px 0 0 9px;
    }

    span:nth-child(1), 
    span:nth-child(2) {
      top: 0;
    }

    span:nth-child(3),
    span:nth-child(4) {
      top: 10px;
    }

    span:nth-child(5), 
    span:nth-child(6) {
      top: 20px;
    }

    &.active span:nth-child(1),
    &.active span:nth-child(6) {
      transform: rotate(45deg);
    }

    &.active span:nth-child(2),
    &.active span:nth-child(5) {
      transform: rotate(-45deg);
    }

    &.active span:nth-child(1) {
      left: 0;
      top: 5px;
    }

    &.active span:nth-child(2) {
      left: calc(50% - 4px);
      top: 5px;
    }

    &.active span:nth-child(3) {
      left: -50%;
      opacity: 0;
    }

    &.active span:nth-child(4) {
      left: 100%;
      opacity: 0;
    }

    &.active span:nth-child(5) {
      left: 0;
      top: 15px;
    }

    &.active span:nth-child(6) {
      left: calc(50% - 4px);
      top: 15px;
    }
  }
}
