.o-footer {
  position: relative;
  z-index: 1;
  padding: 180px 0 50px;
  margin-top: -130px;
  background: linear-gradient(30deg,
          rgba($header-gradient-start, 1) 0%,
          rgba($header-gradient-end ,1) 100%);

  &:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 100%;
    left: 0;
    height: 100px;
    border-bottom-left-radius: 50% 50%;
    border-bottom-right-radius: 50% 50%;
    background-color: $white;
    transform: translateY(50px);
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
    }

    @include laptopDown {
      overflow: hidden;
    }
  }

  &__copy {
    text-align: center;
    font-size: 14px;

    @include tablet {
      text-align: right;
    }
  }
}
