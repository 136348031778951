// Link
.c-link {
  color: inherit;
  text-decoration: none;

  &:hover {
    @extend %decoration-underline;
  }

  &.is-rev {
    @extend %decoration-underline;

    &:hover {
      text-decoration: none;
    }
  }

  &.-clean {
    text-decoration: none!important;
  }
  
  &-arrow {
    > [class^="icon-"] {
      padding-bottom: 3px;
      padding-left: 10px;
      font-size: .75em; //12px
      transform: translateX(0);
      @include transition(transform .3s ease);
    }

    &:hover {
      > [class^="icon-"] {
        transform: translateX(5px);
      }
    }
  }
}

button.c-link {
  padding: 0;
  border: 0;
  background-color: transparent;

  &:hover {
    text-decoration: none;
  }
}

