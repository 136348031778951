/*------------------------------------------------------------------------------------
  BREAKPOINTS
------------------------------------------------------------------------------------*/
$small-breakpoint:              0px;
$smallPlus-breakpoint:          468px;
$medium-breakpoint:             768px;
$mediumPlus-breakpoint:         920px;
$large-breakpoint:              1024px;
$largePlus-breakpoint:          1440px;


/*------------------------------------------------------------------------------------
  CONTAINER
------------------------------------------------------------------------------------*/
$large-maxWidth:                1440px;


/*------------------------------------------------------------------------------------
  GRID, GUTTER
------------------------------------------------------------------------------------*/
$grid-columns:                  12;
$grid-gutter-width:             20px;
$grid-gutter-width-half:        $grid-gutter-width / 2;




