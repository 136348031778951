@font-face {
  font-family: "webfont";
  src: url('../fonts/webfont.eot');
  src: url('../fonts/webfont.eot?#iefix') format('eot'),
  url('../fonts/webfont.woff2') format('woff2'),
  url('../fonts/webfont.woff') format('woff'),
  url('../fonts/webfont.ttf') format('truetype'),
  url('../fonts/webfont.svg#webfont') format('svg');
}

@mixin icon-styles {
  font-family: "webfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include icon-styles;
}

@function icon-char($filename) {
  $char: "";

  @if $filename == camera {
  $char: "\E001";
}
  @if $filename == clock {
  $char: "\E002";
}
  @if $filename == map-marker {
  $char: "\E003";
}
  @if $filename == phone-circle {
  $char: "\E004";
}
  @if $filename == youtube {
  $char: "\E005";
}

@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
&:#{$insert} {
  @if $extend {
    @extend %icon;
  } @else {
    @include icon-styles;
  }
  content: icon-char($filename);
}
}

.icon-camera {
  @include icon(camera);
  line-height: 1;
  display: inline-flex;
}
.icon-clock {
  @include icon(clock);
  line-height: 1;
  display: inline-flex;
}
.icon-map-marker {
  @include icon(map-marker);
  line-height: 1;
  display: inline-flex;
}
.icon-phone-circle {
  @include icon(phone-circle);
  line-height: 1;
  display: inline-flex;
}
.icon-youtube {
  @include icon(youtube);
  line-height: 1;
  display: inline-flex;
}