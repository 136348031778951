.o-logo {
  &__link {}

  &__img {
    display: block;
    max-height: 60px;

    @include mobilePlus {
      max-height: 80px;
    }
  }
}
