.c-txt {
  &-md {
    font-size: 16px;

    @include tablet {
      font-size: 22px;
    }
    
    @include laptop {
      font-size: 26px;
    }
  }

  &-lg {
    font-size: 18px;

    @include tablet {
      font-size: 22px;
    }

    @include laptop {
      font-size: 30px;
    }
  }
}
