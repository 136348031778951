.o-map {
  height: 100%;
  min-height: 400px;
  position: relative;
  z-index: 100;

  iframe {
    height: 400px;
    width: 100%;
  }

  @include tablet {
    min-height: 500px;
    iframe {
      height: 500px;
    }
  }

  @include laptop {
    min-height: 600px;
    margin-right: -100px;
    iframe {
      height: 100%;
    }
  }
}
