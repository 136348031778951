.o-header {
  background: linear-gradient(30deg,
          rgba($header-gradient-start, 1) 0%,
          rgba($header-gradient-end ,1) 100%);

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 60px;
  }

  &__item {}
}
