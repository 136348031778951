.o-cards {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;

  @include tablet {
    padding-bottom: 50px;
  }

  @include tablet {
    flex-direction: row;
  }

  &__item {
    position: relative;
    width: 100%;
    border-radius: $general-border-radius;
    overflow: hidden;
    box-shadow: 0 40px 20px rgba($card-shadow, .18);
    @include transition(background-color .3s ease);

    @include tablet {
      width: 50%;
    }

    &:nth-child(1) {
      z-index: 1;
      background-color: $card-rose-dark;

      &:hover {
        background-color: $card-rose-light;
      }
    }

    &:nth-child(2) {
      margin-top: 60px;
      z-index: 2;
      background-color: $card-rose-light;

      &:hover {
        background-color: $card-rose-dark;
      }

      @include tablet {
        margin-left: -20px;
      }
    }

    &__inner {}

    &__head {
      padding: 40px 30px;
    }

    &__bg {
      height: 300px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    &__title {}
    &__info {}
  }
}
