/***************
  Helper classes for typography
***************/
.txt-upper  {
  text-transform: uppercase;
}

.txt-cap {
  //text-transform: capitalize !important;
}

.txt-init {
  //text-transform: none !important;
}

.lh-def {
  //line-height: $line-height-default;
}

.lh-xl {
  //line-height: $line-height-xl;
}

.is-strike {
  //text-decoration: line-through;
}


/***************
  Helper classes for colors
***************/
.cl-brand {
  color: $color-text-brand;
}

.cl-brand-light {
  color: $color-text-brand-light;
}

.cl-sec {
  //color: $brand-secondary;
}

.cl-white {
  //color: $white;
}

.cl-red {
  //color: $red;
}

.cl-green {
  //color: $green;
}


/***************
  Helper classes for background colors
***************/
.bg-white {
  //background-color: $white;
}

.bg-green {
  //background-color: $green;
}

.bg-red {
  //background-color: $red;
}

.bg-prim {
  //background-color: $brand-primary;
}

.bg-sec {
  //background-color: $brand-secondary;
}


/***************
  Helper classes for border
***************/
.is-rounded {
  //border-radius: $general-border-radius;
}

.b1 {
  //border: 1px solid $border-grey;
}

.b1-prim {
  //border: 1px solid $brand-primary;
}

.b2-prim {
  //border: 2px solid $brand-primary;
}

.b1-sec-l {
  //border: 1px solid $brand-secondary-light;
}


/***************
  Helper classes for widths and heights
***************/
.w-100 {
  //width: 100%;
}

.h-175 {
  //height: 175px;
}

.h-340 {
  //height: 340px;
}

.mw-180 {
  //max-width: 180px;
}

.mw-350 {
  //max-width: 350px;
}

.mw-450 {
  //max-width: 450px;
}

.mw-570 {
  //max-width: 570px;
}

.mw-650 {
  //max-width: 650px;
}

.mw-970 {
  //max-width: 970px;
}

.mw-1000 {
  //max-width: 1000px;
}

.min-h-0 {
  //min-height: 0;
}

.min-w-60 {
  //min-width: 60px;
}

.wh-20 {
  //width: 20px;
  //height: 20px;
}

.wh-60 {
  //width: 60px;
  //height: 60px;
}


/***************
  Helper classes for visibility per breakpoint
***************/
.visible-desktop {
  @include laptopDown {
    //display: none !important;
  }
}

.visible-tab-des {
  @include tabletDown {
    //display: none !important;
  }
}

.visible-mob-only {
  @include tablet {
    //display: none !important;
  }
}

.visible-mob-tab {
  @include laptop {
    //display: none !important;
  }
}


/***************
  Helper classes for box-shadow
***************/
.box-sh {
  //box-shadow: $box-shadow-default;
}


/***************
  Helper classes for flex positioning
***************/
.flex-middle {
  //display: flex;
  //align-items: center;
  //justify-content: center;
}


/***************
  Helper classes for transform
***************/
.rt180 {
  //transform: rotate(180deg);
}
