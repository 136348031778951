.o-team {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -10px;
  z-index: 2;

  @include tablet {
    justify-content: space-between;
  }

  &__item {
    width: 100%;
    padding: 10px;

    @include mobilePlus {
      width: 50%;
    }

    @include tablet {
      width: 33.33%;
    }

    &:nth-child(1) {
      @include tabletDown {
        order: 1;
      }
    }

    &:nth-child(2) {
      @include tabletDown {
        width: 100%;
        order: 0;
      }
    }

    &:nth-child(3) {
      @include tabletDown {
        order: 2;
      }
    }

    &:hover {
      .o-team__item__head-bg {
        transform: scale(1.04);
      }

      .o-team__item__info {
        transform: scale(.9);
      }
    }

    &__head {
      &-bg {
        width: 180px;
        height: 280px;
        margin: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        border-radius: $general-border-radius;
        box-shadow: 0 30px 30px rgba($card-shadow, .25);
        transform: scale(1);
        @include transition(transform .3s ease);

        @include mobilePlus {
          width: 190px;
          height: 290px;
        }

        @include tablet {
          width: 200px;
          height: 300px;
        }

        @include laptop {
          width: 250px;
          height: 350px;
        }
      }
    }

    &__info {
      transform: scale(1);
      @include transition(transform .3s ease);
    }
  }
}
