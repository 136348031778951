.o-contact {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f8f9fa;

  @include laptop {
    flex-direction: row;
    align-items: stretch;
  }

  &__item {
    width: 100%;

    @include laptop {
      width: 50%;
    }

    &__content {
      padding: 50px 30px;

      @include laptop {
        max-width: $large-maxWidth / 2;
        padding: 40px 0 40px 140px;
      }
    }
  }
}

.o-contact-info {
  &__item {
    display: flex;

    &:not(:last-child) {
      padding-bottom: 30px;
      border-bottom: 2px solid $card-rose-dark;
      margin-bottom: 30px;
    }

    .is-icon {
      font-size: 30px;
    }

    .is-info {
      padding-left: 10px;
      font-size: 16px;
      line-height: $line-height-lg;

      .is-title {
        margin-bottom: 15px;
        font-size: 14px;
      }
    }
  }
}
