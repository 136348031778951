.o-services {
  padding: 1000px 5px 30px;
  border-radius: $general-border-radius;
  margin-top: -950px;
  background: linear-gradient(30deg,
          rgba($services-gradient-start, 1) 0%,
          rgba($services-gradient-end ,1) 100%);

  @include mobilePlus {
    padding: 650px 5px 30px;
    margin-top: -600px;
  }

  @include tablet {
    padding: 250px 30px 50px;
    margin-top: -200px;
  }

  .o-swiper {
    padding: 0 30px;

    @include laptop {
      padding: 0 100px;
    }
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &__item {
      height: 100%;
      width: 100%;
      border-radius: $general-border-radius;
      background-color: rgba($white, .8);
      @include transition(background-color .3s ease);

      &:hover {
        background-color: rgba($white, .95);

        .swiper-slide__item__icon:before {
          transform: scale(.8);
        }

        .swiper-slide__item__info {
          transform: scale(1.04);
        }
      }

      &__inner {
        height: 100%;
        padding: 20px;

        @include laptop {
          padding: 30px;
        }
      }

      &__icon {
        &:before {
          content: '';
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          border: 2px solid $color-text-brand;
          margin-bottom: 15px;
          transform: scale(1);
          @include transition(transform .3s ease);
        }
      }

      &__info {
        transform: scale(1);
        @include transition(transform .3s ease);

        strong {
          display: block;
        }
      }
    }
  }
}
