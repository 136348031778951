%decoration-underline {
  text-decoration: underline;
}

%decoration-none {
  text-decoration: none;
}

%text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}