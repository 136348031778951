.o-certfiicates {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row-reverse;

  img {
    display: block;
    width: 100px;
    margin-left: 0;
    transition: all .3s;
    &:not(:first-child) {
      margin-right: -60px;
    }

    @include tablet {
      width: 180px;
      &:not(:first-child) {
        margin-right: -105px;
      }
        //margin: 20px;
    }
  }
}
