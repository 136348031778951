// Font Weight
$font-weight-black: 900;
$font-weight-heavy: 800;
$font-weight-heading: 700;
$font-weight-semiBold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;
$font-weight-thin: 200;


// Font Size
$body-font-size: 18px;

$xsmall-font-size: 12px;

$small-font-size: 14px;
$smallPlus-font-size: 16px;

$medium-font-size: 20px;
$mediumPlus-font-size: 22px;

$large-font-size: 24px;
$largePlus-font-size: 26px;

$xlarge-font-size: 28px;


// Line height
$line-height-default: 1.2;
$line-height-lg: 1.4;
$line-height-xl: 1.6;


// Letter spacing
$letter-spacing-default: 0.02rem;


// Font Family
$font-sans-serif:             -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base:            'Montserrat', $font-sans-serif;
$font-family-heading:         'Montserrat', $font-sans-serif;
$font-family-code:            courier new, Courier, monospace;
