.o-news {
  &__inner {
    padding: 40px 10px;
    border-radius: $general-border-radius;
    background-color: $card-rose-light;
    @include transition(background-color .3s ease);

    &:hover {
      background-color: $card-rose-dark;
    }
  }

  .o-swiper {
    padding: 0 30px;

    .swiper-slide {
      &__item {
        max-width: 400px;
        padding-top: 30px;
        margin: auto;

        @include laptopDown {
          font-size: 16px;
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      top: 35%;
    }
  }
}
